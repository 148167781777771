function Food({dot}) {

	const style = {
		left: `${dot[0]}%`,
		top: `${dot[1]}%`,
	}

	return (
		// <div className='snake-food' style={style}></div>
		<svg className='snake-food' style={style} xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512" fill="none">
			<g clipPath="url(#clip0_6_2)">
			<path d="M437.545 439.881C377.254 390.262 328.705 328.056 295.229 257.753C288.059 242.695 272.956 233.025 256.278 233.024H255.721C239.043 233.024 223.94 242.694 216.769 257.753C183.293 328.055 134.744 390.261 74.4528 439.88C58.1602 453.289 56.9725 477.821 71.8928 492.742L83.198 504.047C93.8014 514.651 110.995 514.651 121.598 504.047C132.202 493.444 149.395 493.444 159.998 504.047C170.601 514.651 187.795 514.651 198.398 504.047C209.001 493.444 226.195 493.444 236.798 504.047C247.401 514.651 264.595 514.651 275.198 504.047C285.801 493.444 302.995 493.444 313.598 504.047C324.201 514.651 341.395 514.651 351.998 504.047C362.601 493.444 379.795 493.444 390.398 504.047C401.001 514.651 418.195 514.651 428.798 504.047L440.103 492.742C455.025 477.822 453.837 453.291 437.545 439.881V439.881Z" fill="url(#paint0_linear_6_2)"/>
			<path d="M437.548 439.878C425.792 430.21 414.488 420.061 403.656 409.459C358.901 365.708 322.178 314.348 295.232 257.757C288.054 242.698 272.955 233.03 256.28 233.02H255.718C248.68 233.02 241.904 234.747 235.9 237.879C227.698 242.156 220.912 249.063 216.766 257.757C189.82 314.348 153.097 365.708 108.342 409.459L108.864 409.981C117.9 419.017 132.537 419.017 141.562 409.981C146.08 405.464 151.993 403.215 157.916 403.215C163.829 403.215 169.742 405.464 174.26 409.981C178.777 414.499 184.69 416.758 190.604 416.758C196.527 416.758 202.44 414.499 206.957 409.981C211.465 405.464 217.388 403.215 223.301 403.215C229.214 403.215 235.137 405.464 239.655 409.981C244.173 414.499 250.086 416.758 255.999 416.758C261.912 416.758 267.825 414.499 272.343 409.981C276.86 405.464 282.784 403.215 288.697 403.215C294.61 403.215 300.533 405.464 305.041 409.981C309.558 414.499 315.471 416.758 321.394 416.758C327.308 416.758 333.221 414.499 337.738 409.981C342.256 405.464 348.169 403.215 354.082 403.215H354.116C356.149 403.218 358.078 404.098 359.515 405.535C371.54 417.55 384.173 429.015 397.371 439.878C413.665 453.29 414.849 477.816 399.931 492.745L389.49 503.185C389.802 503.456 390.103 503.748 390.404 504.049C395.705 509.349 402.652 512 409.599 512C416.546 512 423.493 509.349 428.804 504.049L440.108 492.745C455.026 477.816 453.842 453.29 437.548 439.878V439.878Z" fill="url(#paint1_linear_6_2)"/>
			<path d="M410.576 330.242C359.24 287.993 317.905 235.028 289.401 175.17C283.297 162.349 270.437 154.116 256.237 154.116H255.762C241.562 154.116 228.702 162.349 222.597 175.17C194.093 235.028 152.758 287.993 101.422 330.242C87.55 341.659 86.538 362.546 99.2427 375.25L108.868 384.876C117.897 393.904 132.536 393.904 141.564 384.876C150.592 375.847 165.231 375.847 174.26 384.876C183.288 393.904 197.927 393.904 206.955 384.876C215.984 375.847 230.623 375.847 239.651 384.876C248.679 393.904 263.319 393.904 272.347 384.876C281.375 375.847 296.014 375.847 305.043 384.876C314.071 393.904 328.71 393.904 337.738 384.876C346.767 375.847 361.406 375.847 370.434 384.876C379.462 393.904 394.101 393.904 403.13 384.876L412.755 375.25C425.459 362.546 424.447 341.659 410.576 330.242V330.242Z" fill="url(#paint2_linear_6_2)"/>
			<path d="M410.573 330.24C395.393 317.751 381.098 304.329 367.756 290.063C335.921 256.08 309.478 217.339 289.399 175.174C283.296 162.344 270.435 154.112 256.24 154.112H255.758C248.56 154.112 241.713 156.23 235.911 159.985C230.258 163.639 225.61 168.849 222.599 175.174C202.52 217.339 176.077 256.08 144.242 290.063L147.154 292.984C153.83 299.66 164.662 299.66 171.338 292.984C174.681 289.641 179.058 287.965 183.436 287.965C187.813 287.965 192.19 289.641 195.533 292.984C198.876 296.317 203.253 297.994 207.63 297.994C212.007 297.994 216.384 296.317 219.717 292.984C223.05 289.641 227.427 287.965 231.804 287.965C236.182 287.965 240.559 289.641 243.902 292.984C247.245 296.317 251.622 297.994 255.999 297.994C260.376 297.994 264.753 296.317 268.096 292.984C271.439 289.641 275.816 287.965 280.194 287.965C284.571 287.965 288.948 289.641 292.281 292.984C295.614 296.317 299.991 297.994 304.368 297.994C308.745 297.994 313.122 296.317 316.465 292.984L316.545 292.905C320.453 289.046 326.837 289.319 330.628 293.294C343.089 306.361 356.369 318.701 370.396 330.239C384.27 341.653 385.284 362.545 372.574 375.245L366.26 381.569C367.746 382.483 369.151 383.587 370.436 384.872C374.944 389.39 380.867 391.649 386.78 391.649C392.693 391.649 398.616 389.39 403.134 384.872L412.751 375.245C425.461 362.546 424.447 341.654 410.573 330.24V330.24Z" fill="url(#paint3_linear_6_2)"/>
			<path d="M370.351 227.456C327.979 192.583 294.816 147.843 273.772 97.1617L255.999 54.3545L238.226 97.1607C217.182 147.842 184.019 192.582 141.647 227.455C131.385 235.901 130.636 251.353 140.035 260.751L147.156 267.872C153.835 274.551 164.664 274.551 171.343 267.872C178.022 261.192 188.852 261.192 195.531 267.872C202.21 274.551 213.039 274.551 219.718 267.872C226.397 261.192 237.227 261.192 243.906 267.872C250.585 274.551 261.414 274.551 268.093 267.872C274.772 261.192 285.602 261.192 292.281 267.872C298.96 274.551 309.789 274.551 316.468 267.872C323.147 261.192 333.977 261.192 340.656 267.872C347.335 274.551 358.164 274.551 364.843 267.872L371.964 260.751C381.361 251.353 380.613 235.902 370.351 227.456V227.456Z" fill="url(#paint4_linear_6_2)"/>
			<path d="M371.962 260.749L364.844 267.877C361.511 271.22 357.134 272.886 352.757 272.886C348.38 272.886 344.003 271.22 340.66 267.877C337.588 264.805 333.642 263.148 329.627 262.907L331.785 260.749C341.182 251.352 340.439 235.902 330.179 227.459C318.225 217.621 307.005 206.997 296.583 195.676C282.902 180.816 266.849 168.554 250.208 157.107C248.143 155.686 246.59 153.403 246.171 150.257L245.839 147.767C244.283 136.122 235.037 127.799 224.335 126.484C228.512 118.694 232.367 110.723 235.911 102.601C236.704 100.794 237.477 98.9769 238.23 97.1598L255.999 54.3525L273.768 97.1598C277.925 107.179 282.563 116.967 287.663 126.484C308.293 165.145 336.353 199.479 370.356 227.459C380.616 235.902 381.359 251.352 371.962 260.749Z" fill="url(#paint5_linear_6_2)"/>
			<path d="M224.089 252.629C243.757 252.629 259.701 236.685 259.701 217.017C259.701 197.349 243.757 181.405 224.089 181.405C204.421 181.405 188.477 197.349 188.477 217.017C188.477 236.685 204.421 252.629 224.089 252.629Z" fill="url(#paint6_linear_6_2)"/>
			<path d="M224.089 181.406C220.78 181.406 217.581 181.867 214.541 182.711C229.57 186.885 240.605 200.658 240.605 217.017C240.605 233.376 229.57 247.149 214.541 251.324C217.581 252.168 220.78 252.629 224.089 252.629C243.757 252.629 259.701 236.685 259.701 217.017C259.7 197.349 243.757 181.406 224.089 181.406V181.406Z" fill="url(#paint7_linear_6_2)"/>
			<path d="M315.07 373.749C334.738 373.749 350.682 357.805 350.682 338.137C350.682 318.469 334.738 302.525 315.07 302.525C295.402 302.525 279.458 318.469 279.458 338.137C279.458 357.805 295.402 373.749 315.07 373.749Z" fill="url(#paint8_linear_6_2)"/>
			<path d="M315.07 302.525C311.761 302.525 308.561 302.986 305.521 303.83C320.55 308.004 331.585 321.777 331.585 338.136C331.585 354.495 320.55 368.268 305.521 372.443C308.561 373.287 311.761 373.748 315.07 373.748C334.738 373.748 350.682 357.804 350.682 338.136C350.682 318.469 334.738 302.525 315.07 302.525V302.525Z" fill="url(#paint9_linear_6_2)"/>
			<path d="M178.317 490.319C197.985 490.319 213.929 474.375 213.929 454.707C213.929 435.039 197.985 419.095 178.317 419.095C158.649 419.095 142.704 435.039 142.704 454.707C142.704 474.375 158.649 490.319 178.317 490.319Z" fill="url(#paint10_linear_6_2)"/>
			<path d="M178.317 419.096C175.008 419.096 171.808 419.557 168.768 420.401C183.797 424.576 194.832 438.348 194.832 454.707C194.832 471.066 183.797 484.839 168.768 489.014C171.808 489.859 175.008 490.319 178.317 490.319C197.984 490.319 213.929 474.375 213.929 454.707C213.929 435.039 197.984 419.096 178.317 419.096V419.096Z" fill="url(#paint11_linear_6_2)"/>
			<path d="M199.279 365.619C212.982 365.619 224.089 354.512 224.089 340.809C224.089 327.107 212.982 316 199.279 316C185.577 316 174.47 327.107 174.47 340.809C174.47 354.512 185.577 365.619 199.279 365.619Z" fill="url(#paint12_linear_6_2)"/>
			<path d="M315.07 479.517C328.772 479.517 339.88 468.41 339.88 454.707C339.88 441.005 328.772 429.897 315.07 429.897C301.368 429.897 290.26 441.005 290.26 454.707C290.26 468.41 301.368 479.517 315.07 479.517Z" fill="url(#paint13_linear_6_2)"/>
			<path d="M265.451 188.428C279.153 188.428 290.261 177.32 290.261 163.618C290.261 149.916 279.153 138.808 265.451 138.808C251.749 138.808 240.641 149.916 240.641 163.618C240.641 177.32 251.749 188.428 265.451 188.428Z" fill="url(#paint14_linear_6_2)"/>
			<path d="M311.673 47.0689C320.95 40.1398 312.86 25.6422 302.092 29.8978L299.758 30.8204C284.81 36.7295 268.286 27.0276 266.162 11.0953L265.83 8.60762C264.3 -2.86921 247.698 -2.86921 246.168 8.60762L245.836 11.0953C243.712 27.0276 227.188 36.7295 212.24 30.8204L209.906 29.8978C199.138 25.6412 191.048 40.1398 200.325 47.0689C213.563 56.9565 213.563 76.798 200.325 86.6856C191.048 93.6147 199.138 108.112 209.906 103.857L212.24 102.934C227.188 97.025 243.712 106.727 245.836 122.659L246.168 125.147C247.698 136.624 264.3 136.624 265.83 125.147L266.162 122.659C268.286 106.727 284.81 97.025 299.758 102.934L302.092 103.857C312.86 108.113 320.95 93.6147 311.673 86.6856C298.435 76.799 298.435 56.9575 311.673 47.0689V47.0689Z" fill="url(#paint15_linear_6_2)"/>
			<path d="M311.673 86.6866C298.435 76.799 298.435 56.9575 311.673 47.0699C311.938 46.8721 312.189 46.6673 312.427 46.4575C320.486 39.3276 312.552 25.7646 302.092 29.8988L299.758 30.8214C284.81 36.7295 268.287 27.0286 266.162 11.0953L265.83 8.60762C264.3 -2.86921 247.698 -2.86921 246.168 8.60762L245.836 11.0953C245.684 12.2398 245.457 13.3512 245.162 14.4274C242.205 25.1964 252.777 34.9124 263.209 30.9278C263.302 30.8927 263.393 30.8565 263.485 30.8204L265.819 29.8978C270.92 27.8809 275.421 30.0745 277.759 33.6776C280.355 37.6802 280.283 43.4226 275.402 47.0689C262.163 56.9565 262.163 76.798 275.402 86.6856C284.678 93.6147 276.588 108.113 265.82 103.857L263.486 102.934C263.395 102.898 263.303 102.862 263.21 102.827C252.777 98.8411 242.205 108.558 245.162 119.327C245.457 120.403 245.684 121.515 245.836 122.659L246.168 125.147C247.698 136.624 264.3 136.624 265.83 125.147L266.162 122.659C268.286 106.727 284.81 97.025 299.757 102.933L302.091 103.856C312.86 108.113 320.951 93.6157 311.673 86.6866V86.6866Z" fill="url(#paint16_linear_6_2)"/>
			</g>
			<defs>
			<linearGradient id="paint0_linear_6_2" x1="-59.5677" y1="372.512" x2="431.191" y2="372.512" gradientUnits="userSpaceOnUse">
			<stop stopColor="#97D729"/>
			<stop offset="0.5652" stopColor="#02905D"/>
			<stop offset="0.9955" stopColor="#026841"/>
			</linearGradient>
			<linearGradient id="paint1_linear_6_2" x1="-267.379" y1="372.51" x2="384.277" y2="372.51" gradientUnits="userSpaceOnUse">
			<stop stopColor="#97D729"/>
			<stop offset="0.5652" stopColor="#02905D"/>
			<stop offset="0.9955" stopColor="#026841"/>
			</linearGradient>
			<linearGradient id="paint2_linear_6_2" x1="32.7931" y1="272.881" x2="449.098" y2="272.881" gradientUnits="userSpaceOnUse">
			<stop stopColor="#97D729"/>
			<stop offset="0.5652" stopColor="#02905D"/>
			<stop offset="0.9955" stopColor="#026841"/>
			</linearGradient>
			<linearGradient id="paint3_linear_6_2" x1="-20.0795" y1="272.881" x2="403.605" y2="272.881" gradientUnits="userSpaceOnUse">
			<stop stopColor="#97D729"/>
			<stop offset="0.5652" stopColor="#02905D"/>
			<stop offset="0.9955" stopColor="#026841"/>
			</linearGradient>
			<linearGradient id="paint4_linear_6_2" x1="150.744" y1="163.618" x2="394.422" y2="163.618" gradientUnits="userSpaceOnUse">
			<stop stopColor="#97D729"/>
			<stop offset="0.5652" stopColor="#02905D"/>
			<stop offset="0.9955" stopColor="#026841"/>
			</linearGradient>
			<linearGradient id="paint5_linear_6_2" x1="44.1716" y1="163.619" x2="411.619" y2="163.619" gradientUnits="userSpaceOnUse">
			<stop stopColor="#97D729"/>
			<stop offset="0.5652" stopColor="#02905D"/>
			<stop offset="0.9955" stopColor="#026841"/>
			</linearGradient>
			<linearGradient id="paint6_linear_6_2" x1="168.659" y1="217.017" x2="264.191" y2="217.017" gradientUnits="userSpaceOnUse">
			<stop stopColor="#62DBFB"/>
			<stop offset="0.4361" stopColor="#01D0FB"/>
			<stop offset="0.5962" stopColor="#01B9FB"/>
			<stop offset="0.9305" stopColor="#017FFB"/>
			<stop offset="1" stopColor="#0172FB"/>
			</linearGradient>
			<linearGradient id="paint7_linear_6_2" x1="155.106" y1="217.017" x2="256.02" y2="217.017" gradientUnits="userSpaceOnUse">
			<stop stopColor="#62DBFB"/>
			<stop offset="0.4361" stopColor="#01D0FB"/>
			<stop offset="0.5962" stopColor="#01B9FB"/>
			<stop offset="0.9305" stopColor="#017FFB"/>
			<stop offset="1" stopColor="#0172FB"/>
			</linearGradient>
			<linearGradient id="paint8_linear_6_2" x1="279.459" y1="338.137" x2="369.667" y2="338.137" gradientUnits="userSpaceOnUse">
			<stop stopColor="#FE646F"/>
			<stop offset="0.5213" stopColor="#E41F2D"/>
			<stop offset="1" stopColor="#C41926"/>
			</linearGradient>
			<linearGradient id="paint9_linear_6_2" x1="243.301" y1="338.137" x2="350.215" y2="338.137" gradientUnits="userSpaceOnUse">
			<stop stopColor="#FE646F"/>
			<stop offset="0.5213" stopColor="#E41F2D"/>
			<stop offset="1" stopColor="#C41926"/>
			</linearGradient>
			<linearGradient id="paint10_linear_6_2" x1="142.705" y1="454.707" x2="232.915" y2="454.707" gradientUnits="userSpaceOnUse">
			<stop stopColor="#FEF056"/>
			<stop offset="0.5213" stopColor="#F4D902"/>
			<stop offset="1" stopColor="#FE9738"/>
			</linearGradient>
			<linearGradient id="paint11_linear_6_2" x1="106.549" y1="454.707" x2="235.018" y2="454.707" gradientUnits="userSpaceOnUse">
			<stop stopColor="#FEF056"/>
			<stop offset="0.5213" stopColor="#F4D902"/>
			<stop offset="1" stopColor="#FE9738"/>
			</linearGradient>
			<linearGradient id="paint12_linear_6_2" x1="176.321" y1="340.809" x2="243.651" y2="340.809" gradientUnits="userSpaceOnUse">
			<stop stopColor="#EAF6FF"/>
			<stop offset="0.5213" stopColor="#D8ECFE"/>
			<stop offset="1" stopColor="#B3DAFE"/>
			</linearGradient>
			<linearGradient id="paint13_linear_6_2" x1="292.111" y1="454.707" x2="359.441" y2="454.707" gradientUnits="userSpaceOnUse">
			<stop stopColor="#EAF6FF"/>
			<stop offset="0.5213" stopColor="#D8ECFE"/>
			<stop offset="1" stopColor="#B3DAFE"/>
			</linearGradient>
			<linearGradient id="paint14_linear_6_2" x1="242.491" y1="163.618" x2="309.822" y2="163.618" gradientUnits="userSpaceOnUse">
			<stop stopColor="#EAF6FF"/>
			<stop offset="0.5213" stopColor="#D8ECFE"/>
			<stop offset="1" stopColor="#B3DAFE"/>
			</linearGradient>
			<linearGradient id="paint15_linear_6_2" x1="190.242" y1="66.8782" x2="318.277" y2="66.8782" gradientUnits="userSpaceOnUse">
			<stop stopColor="#FEF056"/>
			<stop offset="0.5213" stopColor="#F4D902"/>
			<stop offset="1" stopColor="#FE9738"/>
			</linearGradient>
			<linearGradient id="paint16_linear_6_2" x1="160.877" y1="66.8782" x2="318.608" y2="66.8782" gradientUnits="userSpaceOnUse">
			<stop stopColor="#FEF056"/>
			<stop offset="0.5213" stopColor="#F4D902"/>
			<stop offset="1" stopColor="#FE9738"/>
			</linearGradient>
			<clipPath id="clip0_6_2">
			<rect width="512" height="512" fill="white"/>
			</clipPath>
			</defs>
		</svg>
	)
}

export default Food